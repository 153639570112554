define("admin/adapters/shot-thumbnail", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    namespace: `${_environment.default.api.namespace}/admin`,
    aeroOperations: (headers, missionId, operationType) => {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/missions/${missionId}/aero_operations`,
        method: 'POST',
        dataType: 'json',
        data: {
          operation_type: operationType
        },
        headers
      });
    }
  });
});